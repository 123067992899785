<template>
  <section class="container-fluid mt-4 pso-dashboard">
    <div class="row no-gutters mb-2">
      <div class="offset-md-1 col-md-11 font-weight-bold text-h5">
        แผนระดับประเทศ
        <span
          class="text-gray text-medium pointer"
          v-tooltip="{
            content:
              'แผนภูมิแสดงความเชื่อมโยงระหว่างนโยบายชาติด้านพลังงาน (ตั้งแต่ยุทธศาสตร์ชาติ 20 ปี ถึงยุทธศาสตร์/แผนของ พน.) กับแผนเชิงพื้นที่ (ยุทธศาสตร์การพัฒนาจังหวัด/กลุ่มจังหวัด และแผนพลังงานระดับภาค)',
          }"
        ><u>ข้อมูลเพิ่มเติม</u>
        </span>
      </div>
    </div>
    <div class="row no-gutters align-items-center my-3">
      <div class="col-md-1 font-weight-bold">ยุทธศาสตร์ชาติ 20 ปี</div>
      <div
        class="
          col-md-11
          font-weight-bold
          text-h5
          plan-message
          bg-dark-yellow
          shadow-normal
          py-1
          text-center
        "
        v-html="$options.filters.nl2br(nationalPlan.strategy)"
      ></div>
    </div>
    <div class="row no-gutters align-items-center my-3">
      <div class="col-md-1 font-weight-bold">
        แผนแม่บทภายใต้ยุทธศาสตร์ชาติ 20 ปี
      </div>
      <div
        class="
          col-md-11
          font-weight-bold
          text-h5
          plan-message
          bg-green
          shadow-normal
          py-1
          text-center
        "
        v-html="$options.filters.nl2br(nationalPlan.master_plan)"
      ></div>
    </div>
    <div class="row no-gutters align-items-center my-3">
      <div class="col-md-1 font-weight-bold">แผนปฏิรูปประเทศด้านพลังงาน</div>
      <div class="col-md-11 text-medium py-1">
        <div class="d-flex">
          <div
            v-for="reform in nationalPlan.reform_plans"
            class="mr-2 bg-white rounded-normal shadow-normal"
            v-bind:key="reform.id"
          >
            <div class="mt-2 pl-2 pr-2 mb-2 message-title border-blue">
              {{ reform.title }}
            </div>
            <div
              class="mt-2 pl-2 pr-2 mb-2 prewrap text-light-ipepp"
              v-html="reform.description"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters mb-2">
      <div
        class="offset-md-1 col-md-11 font-weight-bold text-medium text-center"
      >
        {{ nationalPlan.national_strategy_title }}
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-1 font-weight-bold">
        <div class="h-50 position-relative">
          <div class="position-absolute top-40">เรื่องที่</div>
        </div>
        <div class="h-50 position-relative">
          <div class="position-absolute bottom-50">แนวทางการพัฒนา</div>
        </div>
      </div>
      <div class="col-md-11">
        <div class="row no-gutters">
          <div
            class="
              col
              p-1
              pt-2
              pb-3
              d-flex
              flex-column
              position-relative
              pointer
              border-gray border-width-2
            "
            v-for="(strategy, index) in nationalPlan.national_strategies"
            v-bind:key="strategy.id"
            :class="[
              index == strategySelectedIndex ? strategySelectedClass : '',
            ]"
            @click="selectNationalStrategy(index, strategy.id)"
          >
            <div
              class="
                flex-grow-1
                text-medium
                mx-1
                bg-white
                rounded-normal
                shadow-normal
                d-flex
                align-content-between
                flex-wrap
                mb-2
              "
            >
              <div
                class="w-100 mt-2 pl-2 pr-2 mb-2 message-title font-weight-bold"
                :class="borderForIndex(index)"
              >
                {{ parseInt(index) + 1 }}. {{ strategy.title }}
              </div>
              <div
                v-if="strategy.tag"
                class="d-flex flex-wrap mt-2 mb-2 text-light-ipepp"
              >
                <div
                  v-for="(tag, index) in strategy.tag.split(/,/)"
                  class="mx-2 font-weight-bold"
                  v-bind:key="index"
                >
                  {{ tag }}
                </div>
              </div>
            </div>
            <div
              class="
                flex-grow-1
                text-medium
                mx-1
                bg-white
                rounded-normal
                shadow-normal
                d-flex
                align-content-between
                flex-wrap
              "
            >
              <div
                class="mt-2 pl-2 pr-2 mb-2 message-title text-light-ipepp"
                :class="borderLightForIndex(index)"
                v-html="strategy.approach"
              ></div>
            </div>
            <div class="position-absolute outline-border-bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white pb-3 row border-top border-gray border-width-2">
      <div class="col-12">
        <div
          class="ml-4 mt-4 pl-2 mb-2 font-weight-bold text-h5 message-title"
          :class="borderForIndex(strategySelectedIndex)"
        >
          แผนเชิงพื้นที่
          <!--ปี {{ currentThaiYear }}-->
          ที่สอดคล้องกับเรื่องที่ {{ selectedNationalStrategy.order }}
          {{ selectedNationalStrategy.title }}
        </div>
      </div>
      <div class="col-12 mb-3">
        <div class="row mx-2">
          <div class="col-md-7">
            <div class="box border">
              <div class="box-header no-border d-flex justify-content-between">
                <div class="font-weight-bold d-flex align-items-center">
                  <div>
                    {{ nationalPlan.province_development_strategy_title }}
                  </div>
                  <div
                    class="text-gray text-medium pointer ml-2"
                    v-tooltip="{
                      content: `เนื้อหาของยุทธศาสตร์การพัฒนาจังหวัด เฉพาะส่วนที่สอดคล้องกับแผนงาน ${selectedNationalStrategy.order} ของแผนแม่บท พน.`,
                    }"
                  >
                    <u>ข้อมูลเพิ่มเติม</u>
                  </div>
                </div>
                <button
                  v-show="allowEdit"
                  class="btn btn-outline-red"
                  @click="modalAreaPlanStrategiesForm"
                >
                  เพิ่มยุทธศาสตร์
                </button>
              </div>
              <div class="box-body pt-0">
                <div class="mb-3">ประเด็นยุทธศาสตร์</div>
                <div
                  v-for="areaPlanStrategy in selectedAreaPlanStrategies"
                  class="row mb-2"
                  v-bind:key="areaPlanStrategy.id"
                >
                  <div class="col-3 text-center">
                    {{ areaPlanStrategy.strategy_number }}
                  </div>
                  <div class="col-9">
                    {{ areaPlanStrategy.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="col-md-12 mb-4">
              <div class="box border">
                <div
                  class="box-header no-border d-flex justify-content-between"
                >
                  <div class="font-weight-bold d-flex align-items-center">
                    <div>
                      {{
                        nationalPlan.province_group_development_strategy_title
                      }}
                    </div>
                    <div
                      class="text-gray text-medium pointer ml-2"
                      v-tooltip="{
                        content: `เนื้อหาของยุทธศาสตร์การพัฒนากลุ่มจังหวัด เฉพาะส่วนที่ สพจ.เห็นควรนำมาผลักดันดำเนินการในจังหวัดของท่าน`,
                      }"
                    >
                      <u>ข้อมูลเพิ่มเติม</u>
                    </div>
                  </div>
                  <button
                    v-show="allowEdit"
                    class="btn btn-outline-red"
                    @click="modalRegionEnergyPlanGroupForm"
                  >
                    แก้ไข
                  </button>
                </div>
                <div class="box-body">
                  {{
                    currentReport.province_group_development_strategy | excerpt
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box border">
                <div
                  class="box-header no-border d-flex justify-content-between"
                >
                  <div class="font-weight-bold d-flex align-items-center">
                    <div>{{ nationalPlan.region_energy_plan_title }}</div>
                    <div
                      class="text-gray text-medium pointer ml-2"
                      v-tooltip="{
                        content: `เนื้อหาของแผนพลังงานระดับภาค เฉพาะส่วนที่ สพจ.เห็นควรนำมาผลักดันดำเนินการในจังหวัดของท่าน`,
                      }"
                    >
                      <u>ข้อมูลเพิ่มเติม</u>
                    </div>
                  </div>
                  <button
                    v-show="allowEdit"
                    class="btn btn-outline-red"
                    @click="modalRegionEnergyPlanForm"
                  >
                    แก้ไข
                  </button>
                </div>
                <div class="box-body">
                  {{ currentReport.region_energy_plan_in_province | excerpt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white pb-3 row" v-if="selectedTargets.length > 0">
      <div class="col-12">
        <div class="ml-4 mt-4 font-weight-bold text-h5">เป้าหมาย</div>
      </div>
      <div class="col-12 mb-3">
        <div class="row mx-2">
          <div
            class="mb-3 col-3"
            v-for="(target, index) in selectedTargets"
            v-bind:key="target.id"
          >
            <div class="box border">
              <div class="box-header no-border font-weight-bold">
                เป้าหมายที่ {{ parseInt(index) + 1 }}
              </div>
              <div class="box-body">
                {{ target.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white pb-5 d-flex flex-row-reverse">
      <router-link
        :to="{ name: 'peno.current_situation' }"
        class="btn btn-action py-1 px-4 mr-3"
      >
        ไปต่อ (สถานการณ์ปัจจุบันด้านพลังงาน)
        <eva-icon name="arrow-ios-forward" fill="currentColor"></eva-icon>
      </router-link>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import PenoAreaPlanProvinceStrategyForm from '@/components/PenoAreaPlanProvinceStrategyForm.vue';
import PenoRegionEnergyPlanForm from '@/components/PenoRegionEnergyPlanForm.vue';
import PenoRegionEnergyPlanGroupForm from '@/components/PenoRegionEnergyPlanGroupForm.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {head, find, filter, orderBy} from 'lodash';

export default {
  name: 'plan-relation',

  data() {
    return {
      strategySelectedIndex: 0,
      strategySelectedID: null,
      strategySelectedClass:
        'strategy-selected border-top border-left border-right rounded-top bg-white',
    };
  },

  created() {
    this.strategySelectedID = head(this.nationalPlan.national_strategies).id;
  },

  computed: {
    ...mapGetters(['latestNationalPlan']),
    ...mapState('peno', ['currentReport', 'allowEdit']),
    nationalPlan() {
      return this.latestNationalPlan;
    },
    currentThaiYear() {
      return this.$options.filters.thai_year(this.nationalPlan.year);
    },
    selectedNationalStrategy() {
      return this.nationalPlan.national_strategies.find(
        (str) => str.id === this.strategySelectedID
      );
    },
    selectedAreaPlanStrategies() {
      return orderBy(
        filter(this.currentReport.area_plan_strategies, {
          national_strategy_id: this.strategySelectedID,
        }),
        'strategy_number',
        'asc'
      );
    },
    selectedTargets() {
      return filter(this.currentReport.targets, {
        national_strategy_id: this.strategySelectedID,
      });
    },
  },

  methods: {
    borderForIndex(index) {
      const list = [
        'border-yellow',
        'border-purple',
        'border-green',
        'border-pink',
        'border-orange',
        'border-teal',
        'border-red',
        'border-indigo',
      ];
      return list[index % 8];
    },
    borderLightForIndex(index) {
      const list = [
        'border-light-yellow',
        'border-light-purple',
        'border-light-green',
        'border-light-pink',
        'border-light-orange',
        'border-light-teal',
        'border-light-red',
        'border-light-indigo',
      ];
      return list[index % 8];
    },
    selectNationalStrategy(index, strategyID) {
      this.strategySelectedIndex = index;
      this.strategySelectedID = strategyID;
    },
    modalAreaPlanStrategiesForm() {
      this.$modal.show(
        PenoAreaPlanProvinceStrategyForm,
        {
          name: 'area_plan_province_strategy_form',
          nationalPlan: this.nationalPlan,
          areaPlanStrategies: this.selectedAreaPlanStrategies,
          nationalStrategyID: this.strategySelectedID,
          order: this.selectedNationalStrategy.order,
        },
        {
          name: 'area_plan_province_strategy_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    modalRegionEnergyPlanForm() {
      this.$modal.show(
        PenoRegionEnergyPlanForm,
        {
          name: 'region_energy_plan_form',
          nationalPlan: this.nationalPlan,
          regionEnergyPlanContent:
          this.currentReport.region_energy_plan_content,
          regionEnergyPlanInProvince:
          this.currentReport.region_energy_plan_in_province,
        },
        {
          name: 'region_energy_plan_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    modalRegionEnergyPlanGroupForm() {
      this.$modal.show(
        PenoRegionEnergyPlanGroupForm,
        {
          name: 'region_energy_plan_group_form',
          nationalPlan: this.nationalPlan,
          provinceGroupDevelopmentStrategy:
          this.currentReport.province_group_development_strategy,
        },
        {
          name: 'region_energy_plan_group_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.strategy-selected {
  .shadow-normal {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    transition: 1s ease-in-out;
  }

  .outline-border-bottom {
    width: 100%;
    height: 2px;
    left: 0px;
    bottom: -2px;
    background-color: white;
  }
}

.affix {
  width: inherit;
  max-width: inherit;
  padding-right: 2.7rem !important;
}
</style>
