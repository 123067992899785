var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"\n          box-header\n          text-h4\n          font-weight-bold\n          d-flex\n          justify-content-between\n        "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v(_vm._s(_vm.nationalPlan.province_development_strategy_title))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: ("โปรดกรอกชื่อประเด็นยุทธศาสตร์ พร้อมข้อความจากประเด็นยุทธศาสตร์ ที่สอดคล้องกับแผนงาน " + _vm.order + " ของแผนแม่บท พน.\n"),
            }),expression:"{\n              content: `โปรดกรอกชื่อประเด็นยุทธศาสตร์ พร้อมข้อความจากประเด็นยุทธศาสตร์ ที่สอดคล้องกับแผนงาน ${order} ของแผนแม่บท พน.\n`,\n            }"}],staticClass:"text-gray text-medium pointer ml-3"},[_c('u',[_vm._v("คำแนะนำ")])])]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$modal.hide(_vm.name)}}},[_c('eva-icon',{attrs:{"name":"close-outline"}})],1)]),_c('div',{staticClass:"box-body"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex mb-3"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("ประเด็นยุทธศาสตร์")]),_c('button',{staticClass:"btn btn-outline-green py-1 px-2 ml-4",attrs:{"type":"button"},on:{"click":_vm.addStrategy}},[_c('eva-icon',{attrs:{"name":"plus"}}),_vm._v(" เพิ่ม ")],1)]),_vm._l((_vm.credentials.$v
                .area_plan_strategies.$each.$iter),function(area_plan_strategy,index){return _c('div',{key:index,staticClass:"row no-gutters mb-2"},[_c('label',{staticClass:"col-md-2 col-form-label text-center required"},[_vm._v("ประเด็นยุทธศาสตร์ที่")]),_c('div',{staticClass:"col-md-2 mr-2 flex-column"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(area_plan_strategy.strategy_number.$model),expression:"area_plan_strategy.strategy_number.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"number","placeholder":"","min":"1"},domProps:{"value":(area_plan_strategy.strategy_number.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(area_plan_strategy.strategy_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(area_plan_strategy.strategy_number.$dirty)?_c('div',{staticClass:"error text-danger"},[(!area_plan_strategy.strategy_number.required)?_c('div',[_vm._v(" Required ")]):_vm._e(),(!area_plan_strategy.strategy_number.maxValue)?_c('div',[_vm._v(" เกินตัวเลขที่กำหนด ")]):_vm._e()]):_vm._e(),(
                    _vm.credentials.errors.loGet(
                      ("area_plan_strategies[" + index + "].strategy_number")
                    )
                  )?_c('div',{staticClass:"text-danger text-break"},[_vm._v(" "+_vm._s(_vm.credentials.errors.loGet( ("area_plan_strategies[" + index + "].strategy_number") ))+" ")]):_vm._e()]),_c('div',{staticClass:"col mr-2"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(area_plan_strategy.name.$model),expression:"area_plan_strategy.name.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"รายละเอียด"},domProps:{"value":(area_plan_strategy.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(area_plan_strategy.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(area_plan_strategy.name.$dirty)?_c('div',{staticClass:"error text-danger"},[(!area_plan_strategy.name.required)?_c('div',{staticClass:"text-danger"},[_vm._v(" Required ")]):_vm._e(),(!area_plan_strategy.name.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" เกินจำนวนตัวอักษรสูงสุด ")]):_vm._e()]):_vm._e(),(
                    _vm.credentials.errors.loGet(
                      ("area_plan_strategies[" + index + "].name")
                    )
                  )?_c('div',{staticClass:"text-danger text-break"},[_vm._v(" "+_vm._s(_vm.credentials.errors.loGet( ("area_plan_strategies[" + index + "].name") ))+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-1"},[_c('button',{staticClass:"btn btn-outline-red py-1 px-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeStrategy(index)}}},[_vm._v(" ลบ ")])])])})],2)])]),_c('div',{staticClass:"box-footer"},[_c('div',{staticClass:"form-group text-right mt-3"},[_c('button',{directives:[{name:"promise-btn",rawName:"v-promise-btn"}],staticClass:"btn btn-action",on:{"click":_vm.submit}},[_vm._v(" ยืนยันการแก้ไข ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }