<template>
  <div class="mb-4">
    <div class="box">
      <div
        class="
          box-header
          text-h4
          font-weight-bold
          d-flex
          justify-content-between
        "
      >
        <div class="d-flex align-items-center">
          <div>{{ nationalPlan.province_development_strategy_title }}</div>
          <div
            class="text-gray text-medium pointer ml-3"
            v-tooltip="{
              content: `โปรดกรอกชื่อประเด็นยุทธศาสตร์ พร้อมข้อความจากประเด็นยุทธศาสตร์ ที่สอดคล้องกับแผนงาน ${order} ของแผนแม่บท พน.
`,
            }"
          >
            <u>คำแนะนำ</u>
          </div>
        </div>
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <div class="box-body">
        <div class="">
          <div class="form-group">
            <div class="d-flex mb-3">
              <label class="font-weight-bold">ประเด็นยุทธศาสตร์</label>
              <button
                type="button"
                @click="addStrategy"
                class="btn btn-outline-green py-1 px-2 ml-4"
              >
                <eva-icon name="plus"></eva-icon> เพิ่ม
              </button>
            </div>
            <div
              class="row no-gutters mb-2"
              v-for="(area_plan_strategy, index) in credentials.$v
                .area_plan_strategies.$each.$iter"
              v-bind:key="index"
            >
              <label class="col-md-2 col-form-label text-center required"
                >ประเด็นยุทธศาสตร์ที่</label
              >
              <div class="col-md-2 mr-2 flex-column">
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  min="1"
                  v-model.trim="area_plan_strategy.strategy_number.$model"
                />
                <div
                  class="error text-danger"
                  v-if="area_plan_strategy.strategy_number.$dirty"
                >
                  <div v-if="!area_plan_strategy.strategy_number.required">
                    Required
                  </div>
                  <div v-if="!area_plan_strategy.strategy_number.maxValue">
                    เกินตัวเลขที่กำหนด
                  </div>
                </div>
                <div
                  v-if="
                    credentials.errors.loGet(
                      `area_plan_strategies[${index}].strategy_number`
                    )
                  "
                  class="text-danger text-break"
                >
                  {{
                    credentials.errors.loGet(
                      `area_plan_strategies[${index}].strategy_number`
                    )
                  }}
                </div>
              </div>
              <div class="col mr-2">
                <input
                  type="text"
                  v-model.trim="area_plan_strategy.name.$model"
                  class="form-control"
                  placeholder="รายละเอียด"
                />
                <div
                  class="error text-danger"
                  v-if="area_plan_strategy.name.$dirty"
                >
                  <div
                    v-if="!area_plan_strategy.name.required"
                    class="text-danger"
                  >
                    Required
                  </div>
                  <div
                    v-if="!area_plan_strategy.name.maxLength"
                    class="text-danger"
                  >
                    เกินจำนวนตัวอักษรสูงสุด
                  </div>
                </div>
                <div
                  v-if="
                    credentials.errors.loGet(
                      `area_plan_strategies[${index}].name`
                    )
                  "
                  class="text-danger text-break"
                >
                  {{
                    credentials.errors.loGet(
                      `area_plan_strategies[${index}].name`
                    )
                  }}
                </div>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  @click="removeStrategy(index)"
                  class="btn btn-outline-red py-1 px-4"
                >
                  ลบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="form-group text-right mt-3">
          <button v-promise-btn @click="submit" class="btn btn-action">
            ยืนยันการแก้ไข
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Form from '../modules/form/form';
import {
  required,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import { nth, reduce, cloneDeep } from 'lodash';

export default {
  name: 'PenoAreaPlanProvinceStrategyForm',

  props: {
    name: {
      type: String,
      required: true,
    },
    nationalPlan: {
      type: Object,
      required: true,
    },
    areaPlanStrategies: {
      type: Array,
      required: true,
    },
    nationalStrategyID: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      credentials: new Form({
        data: () => {
          return {
            national_strategy_id: this.nationalStrategyID,
            area_plan_strategies: cloneDeep(this.areaPlanStrategies),
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          area_plan_strategies: {
            $each: {
              strategy_number: {
                required,
                maxValue: maxValue(65534),
              },
              name: {
                required,
                maxLength: maxLength(255),
              },
            },
          },
        },
      }),
    };
  },

  created() {},

  methods: {
    addStrategy() {
      this.credentials.area_plan_strategies.push({
        strategy_number: '',
        name: '',
        national_strategy_id: this.nationalStrategyID,
      });
      this.credentials.$v.$reset();
    },
    removeStrategy(index) {
      this.credentials.area_plan_strategies.splice(index, 1);
    },
    submit() {
      return this.credentials
        .patch('/api/current_report/area_plan_strategies/update')
        .then((data) => {
          this.$store.commit('peno/setAreaPlanStrategies', data);
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
