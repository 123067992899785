<template>
  <div class="mb-4">
    <div class="box">
      <div
        class="
          box-header
          text-h4
          font-weight-bold
          d-flex
          justify-content-between
        "
      >
        <div class="d-flex align-items-center">
          <div>
            {{ nationalPlan.province_group_development_strategy_title }}
          </div>
          <div
            class="text-gray text-medium pointer ml-3"
            v-tooltip="{
              content: `โปรดกรอกลำดับที่ และชื่อประเด็นยุทธศาสตร์ (ถ้ามี)
พร้อมข้อความจากยุทธศาสตร์การพัฒนากลุ่มจังหวัด เฉพาะส่วนที่ สพจ.เห็นควรนำมาผลักดันดำเนินการในจังหวัดของท่าน`,
            }"
          >
            <u>คำแนะนำ</u>
          </div>
        </div>
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <div class="box-body">
        <div class="">
          <!--<div class="form-group editor">-->
          <!--<label class="font-weight-bold">แผนพลังงานระดับภาคจาก PSO</label>-->
          <!--<div class="">{{ regionEnergyPlanContent | nl2br }}</div>-->
          <!--</div>-->
          <div class="form-group editor">
            <textarea
              class="form-control"
              v-model="credentials.province_group_development_strategy"
            ></textarea>
            <div
              v-if="
                !credentials.$v.province_group_development_strategy.maxLength
              "
              class="text-danger"
            >
              เกินจำนวนตัวอักษรสูงสุด
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="form-group text-right mt-3">
          <button v-promise-btn @click="submit" class="btn btn-action">
            ยืนยันการแก้ไข
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Trumbowyg from 'vue-trumbowyg';
// import 'trumbowyg/dist/ui/trumbowyg.css';
import Form from '../modules/form/form';
import {
  required,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import { nth, reduce, cloneDeep } from 'lodash';

export default {
  name: 'PenoRegionEnergyPlanForm',

  // components: {
  //   Trumbowyg
  // },

  props: {
    name: {
      type: String,
      required: true,
    },
    nationalPlan: {
      type: Object,
      required: true,
    },
    // regionEnergyPlanContent: {
    //   type: String,
    //   default: null
    // },
    provinceGroupDevelopmentStrategy: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      credentials: new Form({
        data: () => {
          return {
            province_group_development_strategy:
              this.provinceGroupDevelopmentStrategy,
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          province_group_development_strategy: {
            maxLength: maxLength(16777215),
          },
        },
      }),
    };
  },

  created() {},

  methods: {
    submit() {
      return this.credentials
        .patch('/api/current_report/region_energy_plan_in_group/update')
        .then((data) => {
          this.$store.commit('peno/setProvinceGroupDevelopmentStrategyp', data);
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
